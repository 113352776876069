import $ from "jquery";

$(function () {
  const assign = Object.assign;
  let form = $(".apps-search-form__tab--normal");
  let wrapper = $(".apps-search-form");

  let get_params = function () {
    let values = {};
    let clean = {};
    let tt_info = {};

    assign(values, form.find(":input[name]").serializeObject());

    $.each(arguments, function (i, argument) {
      assign(values, argument || {});
    });

    $.each(values, function (key, val) {
      let value = $.map($.isArray(val) ? val : [val], function (v) {
        return (v != void 0 && v !== null && v !== "") || v === 0 ? v : null;
      });

      if (value.length == 1) {
        clean[key] = value[0];
      }

      if (value.length > 1) {
        clean[key] = value;
      }
    });

    // чистим от дополнительных параметров, которые не относятся к выбранному типу недвижимости
    let request_target = clean.request_target || null;
    let realty_type = clean.realty_type || null;
    let target_type = request_target + "_" + realty_type;
    let fields = target_type in tt_info ? tt_info[target_type] : [];
    let $fields = $(fields);
    let dependencies = {
      floor: ["floor"],
      floor_from: ["floor"],
      floor_till: ["floor"],
      room: ["amount-of-rooms", "amount-of-bedrooms"],
      is_free_planning: ["is-free-planning"],
      square_from: [
        "square",
        "square-halls",
        "square-rooms",
        "square-hotel-rooms",
        "square-prodaction",
        "square-warehouse",
      ],
      square_till: [
        "square",
        "square-halls",
        "square-rooms",
        "square-hotel-rooms",
        "square-prodaction",
        "square-warehouse",
      ],
      square_land_from: ["square-land-ar", "square-land-ga"],
      square_land_till: ["square-land-ar", "square-land-ga"],
      square_living_from: ["living-square"],
      square_living_till: ["living-square"],
      price_for: ["cost-for-meter", "cost-for-ar", "cost-for-ga"],
    };

    $.each(dependencies, function (param, aliases) {
      if (!$fields.filter($(aliases)).get().length) {
        delete clean[param];
      }
    });

    return clean;
  };

  $(".subscribe__saver-button").on("click", function () {
    let params = get_params({
      "find-name": null,
      "where_name": null,
    });

    if (params.currency && params.currency == "UAH") {
      delete params.currency;
    }

    let button = $(this);
    let input = button
      .closest(".subscribe__form")
      .find(".subscribe__search-input");
    let record_id = button.closest(".subscribe__form").find("[name=record_id]");
    let partner_id = button
      .closest(".subscribe__form")
      .find("[name=partner_id]");
    let json_params = button.closest(".subscribe__form").find("[name=params]");
    let is_new_building = button
      .closest(".subscribe__form")
      .find("[name=is_new_building]");
    let url = button.data("url");
    let loadingClassName = "loading";
    let param = get_params(
      {
        sf_format: "json",
        where_name: null,
      },
      params || {}
    );
    let url2 = wrapper.attr("data-action");

    if (sf_module == "commercial" || "homepage" == sf_module) {
      if (param.request_target && param.realty_type) {
        url2 += param.request_target + "-" + param.realty_type + "/";
        if (typeof param.lj == "string" && param.lj > "") {
          url2 += "rajon-" + param.lj + "/";
          delete param["lj"];
        }
      }
      delete param["request_target"];
      delete param["realty_type"];
    }

    let data = {
      sf_format: "jsonp",
      data: get_params({
        sf_format: "json",
      }),
      email: input.val(),
      partner_id: partner_id.length > 0 ? partner_id.val() : 0,
      record_id: record_id.length > 0 ? record_id.val() : 0,
      params: json_params.length > 0 ? json_params.val() : 0,
      is_new_building: is_new_building.length > 0 ? is_new_building.val() : 0,
      name: button.data("name"),
      url: url2 + "?" + $.param(params),
    };

    if (!button.hasClass(loadingClassName)) {
      $(".subscribe__form").validate({
        rules: {
          email: {
            required: true,
            email: true,
          },
        },
        messages: {
          email: {
            required: "Поле Ваш e-mail обязательно для заполнения",
            email: "Поле Ваш e-mail должно быть правильным email адресом",
          },
        },
        errorClass: "errors",
        errorElement: "p",
        ignore: ".ignore",
        errorPlacement: function (error, element) {
          $(".subscribe__form")
            .closest(".subscribe")
            .addClass("subscribe--status-error");
          return false;
        },
        invalidHandler: function (event, validator) {
          // 'this' refers to the form
          let errors = validator.numberOfInvalids();
          if (errors) {
            $(this).closest(".subscribe").addClass("subscribe--status-error");
            $(".subscribe__saver-button").addClass("ui-button--status-error");
          } else {
            $(".subscribe__form")
              .closest(".subscribe")
              .removeClass("subscribe--status-error");
            $(".subscribe__saver-button").removeClass(
              "ui-button--status-error"
            );
          }
        },
        success: function (label) {
          $(".subscribe__form")
            .closest(".subscribe")
            .removeClass("subscribe--status-error");
          $(".subscribe__saver-button").removeClass("ui-button--status-error");
        },

        submitHandler: function (form) {
          $.ajax({
            url: url,
            dataType: "jsonp",
            data: data,
            beforeSend: function () {
              button.addClass(loadingClassName);
              input.addClass(loadingClassName);
            },
            complete: function () {},
            error: function (data) {
              button.removeClass(loadingClassName);
              input.removeClass(loadingClassName);
            },
            success: function (data) {
              if (data.code == "1") {
                // Такой поиск уже сохранен
                $(".subscribe__dialog")
                  .html("<p>" + data.message + "</p>")
                  .estdialog({
                    draggable: true,
                    autoOpen: true,
                    buttons: [
                      {
                        class: "ui-button ui-button--big command-close-dialog",
                        text: "Хорошо",
                      },
                    ],
                    width: 600,
                  });
              } else {
                $(".subscribe__form")
                  .closest(".subscribe")
                  .removeClass("subscribe--status-error");
                $(".subscribe__form")
                  .closest(".subscribe")
                  .addClass("subscribe--status-success");
                $(".subscribe__saver-button").removeClass(
                  "ui-button--status-error"
                );
              }

              button.removeClass(loadingClassName);
              input.removeClass(loadingClassName);
            },
          });
          return false;
        },
      });
    }
  });

  $(".subscribe__welldone").click(function (e) {
    e.preventDefault();

    $(this).closest(".subscribe").removeClass("subscribe--status-success");
    $(".subscribe__search-input").not(".ignore").val("");
  });
});
